import { resolveAuth } from '@ordergroove/auth';
import * as constants from './constants';
import { api } from './api';
import platform from '../platform';
import { safeProductId } from './utils';

export const optinProduct = (product, frequency, offer) => ({
  type: constants.OPTIN_PRODUCT,
  payload: { product, frequency, offer }
});

export const optoutProduct = (product, offer) => ({
  type: constants.OPTOUT_PRODUCT,
  payload: { product, offer }
});

export const productHasChangedComponents = (newProduct, product) => ({
  type: constants.PRODUCT_HAS_CHANGED,
  payload: { newProduct, product }
});

export const productChangeFrequency = (product, frequency, offer) => ({
  type: constants.PRODUCT_CHANGE_FREQUENCY,
  payload: { product, frequency, offer }
});

export const concludeUpsell = product => ({
  type: constants.CONCLUDE_UPSELL,
  payload: { product }
});

export const setMerchantId = merchantId => ({
  type: constants.SET_MERCHANT_ID,
  payload: merchantId
});

export const createSessionId = merchantId => ({
  type: constants.CREATED_SESSION_ID,
  payload: `${merchantId}.${Math.floor(Math.random() * 999999)}.${Math.round(new Date().getTime() / 1000.0)}`
});

export const requestAuth = payload => ({
  type: constants.REQUEST_AUTH,
  payload
});

export const authorize = (merchantId, sigfield, ts, sig) => ({
  type: constants.AUTHORIZE,
  payload: { public_id: merchantId, sig_field: sigfield, ts, sig }
});

export const unauthorized = reason => ({
  type: constants.UNAUTHORIZED,
  payload: reason
});

export const setAuthUrl = url => ({
  type: constants.SET_AUTH_URL,
  payload: url
});

export const fetchDone = initiator => ({
  type: constants.RECEIVE_FETCH,
  payload: initiator
});

/**
 *
 * @param {*} authResolver For mock purpose.
 */
export const fetchAuth = (authResolver = resolveAuth) =>
  function fetchAuthThunk(dispatch, getState) {
    if (window.og && window.og.previewMode)
      return dispatch(unauthorized({ message: 'Offers are running in preview mode' }));

    const { merchantId, authUrl } = getState();

    const requestAction = requestAuth(authUrl);
    dispatch(requestAction);

    return authResolver(authUrl)
      .then(
        // eslint-disable-next-line camelcase
        ({ sig_field, ts, sig }) => dispatch(authorize(merchantId, sig_field, ts, sig)),
        err => dispatch(unauthorized(err))
      )
      .finally(() => dispatch(fetchDone(requestAction)));
  };

export const requestOrders = (status, ordering) => ({
  type: constants.REQUEST_ORDERS,
  payload: { status, ordering }
});

export const receiveOrders = response => ({
  type: constants.RECEIVE_ORDERS,
  payload: response
});

export const receiveItems = response => ({
  type: constants.RECEIVE_ORDER_ITEMS,
  payload: response
});

/**
 *
 * @param {*} authResolver For mock purpose.
 */
export const fetchOrders = (status = 1, ordering = 'place') =>
  function fetchOrdersThunk(dispatch, getState) {
    const {
      environment: { legoUrl },
      auth
    } = getState();

    if (!auth) return dispatch(unauthorized('No auth set.'));

    const requestAction = requestOrders(status, ordering);
    dispatch(requestAction);

    return api
      .fetchOrders(legoUrl, auth, status, ordering)
      .then(
        // eslint-disable-next-line camelcase
        response => {
          if (response.results) {
            dispatch(receiveOrders(response));
            const nextOrderId = (response.results[0] || {}).public_id;
            if (nextOrderId) {
              return api.fetchItems(legoUrl, auth, nextOrderId).then(res => dispatch(receiveItems(res)));
            }
          }
          dispatch(unauthorized(response.detail));
          return null;
        },
        err => dispatch(unauthorized(err))
      )
      .finally(() => dispatch(fetchDone(requestAction)));
  };

export const setEnvironment = env => {
  switch (env) {
    case constants.ENV_DEV:
      return {
        type: constants.SET_ENVIRONMENT_DEV,
        payload: env
      };
    case constants.ENV_STAGING:
      return {
        type: constants.SET_ENVIRONMENT_STAGING,
        payload: env
      };
    case constants.ENV_PROD:
      return {
        type: constants.SET_ENVIRONMENT_PROD,
        payload: env
      };
    default:
      throw new Error(`${env} is not a supported environment`);
  }
};

/**
 * dispatchs an createSessionId() if sessionId is not present
 */
export const requestSessionId = () => (dispatch, getState) => {
  const { merchantId, sessionId } = getState();
  if (!sessionId || (merchantId && !sessionId.startsWith(merchantId))) {
    dispatch(createSessionId(merchantId));
  }
  return sessionId;
};

export const receiveOffer = (response, offer) => ({
  type: constants.RECEIVE_OFFER,
  payload: { ...response, offer }
});

export const fetchResponseError = err => ({
  type: constants.FETCH_RESPONSE_ERROR,
  payload: err
});

export const requestOffer = (product, module = constants.DEFAULT_OFFER_MODULE, offer) => ({
  type: constants.REQUEST_OFFER,
  payload: { product, module, offer }
});

export const fetchOffer = (product, module = constants.DEFAULT_OFFER_MODULE, offer) =>
  function fetchOfferThunk(dispatch, getState) {
    const {
      merchantId,
      sessionId,
      environment: { apiUrl }
    } = getState();
    const requestAction = requestOffer(product, module, offer);
    dispatch(requestAction);

    const productId = safeProductId(product);

    return api
      .fetchOffer(apiUrl, merchantId, sessionId, productId, module)
      .then(
        response => dispatch(receiveOffer(response, offer)),
        err => dispatch(fetchResponseError(err))
      )
      .finally(() => dispatch(fetchDone(requestAction)));
  };

export const checkout = () => ({
  type: constants.CHECKOUT
});

export const requestCreateOneTime = (product, order, quantity, offerId) => ({
  type: constants.REQUEST_CREATE_IU_ORDER,
  payload: { product, order, quantity, offerId }
});

export const receiveCreateOneTime = payload => ({
  type: constants.CREATE_ONE_TIME,
  payload
});

export const requestConvertOneTimeToSubscription = (item, frequency) => ({
  type: constants.REQUEST_CONVERT_ONE_TIME,
  payload: { item, frequency }
});

export const receiveConvertOneTime = (response, product) => ({
  type: constants.CONVERT_ONE_TIME,
  payload: { response, product }
});

export const createIu = (product, order, quantity, subscribed = false, frequency = null) =>
  function createIuThunk(dispatch, getState) {
    const {
      auth,
      environment: { legoUrl },
      previewUpsellOffer,
      offerId: offer
    } = getState();

    if (!auth) return dispatch(unauthorized('No auth set.'));

    const requestAction = requestCreateOneTime(product, order, quantity, offer);

    dispatch(requestAction);

    return (previewUpsellOffer
      ? Promise.resolve({ legoUrl, product, order, quantity, offer })
      : api.createOneTime(legoUrl, auth, product.id, order, quantity, offer)
    )
      .then(
        item => {
          dispatch(receiveCreateOneTime(item));
          if (subscribed) {
            dispatch(requestConvertOneTimeToSubscription(item, frequency));
            return (previewUpsellOffer
              ? Promise.resolve({ item, frequency })
              : api.convertOneTimeToSubscription(legoUrl, auth, item, frequency, offer)
            ).then(
              response => dispatch(receiveConvertOneTime(response, product)),
              err => dispatch(fetchResponseError(err))
            );
          }
          return item;
        },
        err => dispatch(fetchResponseError(err))
      )

      .finally(() => dispatch(fetchDone(requestAction)));
  };

export const setLocale = payload => ({
  type: constants.SET_LOCALE,
  payload
});

export const setConfig = payload => ({
  type: constants.SET_CONFIG,
  payload
});

export const addTemplate = (selector, markup, config) => ({
  type: constants.ADD_TEMPLATE,
  payload: { selector, markup, config }
});

export const setTemplates = templates => ({
  type: constants.SET_TEMPLATES,
  payload: templates
});

export const setFirstOrderPlaceDate = (product, firstOrderPlaceDate) => ({
  type: constants.SET_FIRST_ORDER_PLACE_DATE,
  payload: { product, firstOrderPlaceDate }
});

export const setProductToSubscribe = (product, productToSubscribe) => ({
  type: constants.SET_PRODUCT_TO_SUBSCRIBE,
  payload: { product, productToSubscribe }
});
