/* eslint-disable no-case-declarations  */
import { combineReducers } from 'redux';
import * as constants from '../core/constants';

import {
  autoshipByDefault,
  auth,
  authUrl,
  defaultFrequencies,
  eligibilityGroups,
  environment,
  firstOrderPlaceDate,
  incentives,
  locale,
  merchantId,
  nextUpcomingOrder,
  optedin as coreOptedin,
  optedout,
  previewStandardOffer,
  previewUpsellOffer,
  productToSubscribe,
  sessionId,
  templates
} from '../core/reducer';
import { safeProductId } from '../core/utils';

const money = val => (val === null ? '' : `$${val.toString().replace(/(\d\d)$/, '.$1')}`);

const percentage = val => `${val}%`;

const mapSellingPlanToDiscount = allocation => {
  let formatted_discount = '';
  if (allocation.price_adjustments[0]?.value_type === 'percentage') {
    formatted_discount = percentage(allocation.price_adjustments[0].value);
  } else if (allocation.price_adjustments[0]?.value) {
    formatted_discount = money(allocation.price_adjustments[0].value);
  } else if (allocation.compare_at_price) {
    formatted_discount = money(allocation.compare_at_price - allocation.price);
  }

  if (formatted_discount) {
    return [money(allocation.compare_at_price), formatted_discount, money(allocation.price)];
  }

  return [money(allocation.price), '', money(allocation.price)];
};

const overrideLineKey = (state, productId, newValue) => {
  const keys = Object.keys(state).filter(it => it.startsWith(productId.toString()));
  if (keys.length) {
    return { ...state, ...keys.reduce((acc, cur) => ({ ...acc, [cur]: newValue }), {}) };
  }
  return state;
};

const getOGSellingPlanGroup = product => {
  const sellingPlanGroup = product?.selling_plan_groups.find(group => {
    return group.name === 'Subscribe and Save';
  });
  return sellingPlanGroup;
};

const productOrVariantInStockReducer = (acc, cur) => ({
  ...overrideLineKey(acc, cur.id, cur.available),
  [cur.id]: cur.available
});

const productTrue = (acc, [id]) => ({ ...acc, [id]: true });

const sellingPlanAllocationsReducer = (acc, cur) => ({
  ...acc,
  // now frequency every_period will match with selling_plan_id so no need to convert it
  [cur.selling_plan_id || cur.selling_plan?.id]: mapSellingPlanToDiscount(cur)
});

const reduceProductCartLine = (acc, cur) => {
  const productId = safeProductId(cur.key);
  return { ...acc, [cur.key]: acc[productId] || null };
};

export const autoshipEligible = (state = {}, action) => {
  if (constants.RECEIVE_PRODUCT_PLANS === action.type) {
    return Object.entries(action.payload).reduce(productTrue, state);
  }
  if (constants.SETUP_CART === action.type) {
    const { payload: cart } = action;
    return cart.items.reduce(reduceProductCartLine, state);
  }
  if (constants.SETUP_PRODUCT === action.type) {
    const { payload: product } = action;
    return [product, ...(product?.variants || [])]?.reduce(
      (acc, cur) => ({
        ...overrideLineKey(acc, cur.id, cur.selling_plan_allocations?.length > 0),
        [cur.id]: cur.selling_plan_allocations?.length > 0
      }),
      state
    );
  }
  return state;
};

export function textToFreq(text) {
  const period = ['day', 'week', 'month'].findIndex(it => text.toLowerCase().includes(it)) + 1;
  const every = (text.match(/(\d+)/) || ['', 1])[1];
  if (every && period) {
    return `${every}_${period}`;
  }
  return null;
}

export const config = (
  state = {
    frequencies: [],
    offerType: 'radio'
  },
  action
) => {
  if (constants.RECEIVE_PRODUCT_PLANS === action.type) {
    const frequencies = [
      ...new Set(
        Object.values(action.payload)
          .map(Object.keys)
          .flat()
      )
    ];
    return {
      ...state,
      frequencies
    };
  }

  if (constants.SETUP_PRODUCT === action.type) {
    const product = action.payload;
    const sellingPlanGroup = getOGSellingPlanGroup(product);
    const frequencies = sellingPlanGroup?.selling_plans?.map(({ id }) => `${id}`);
    if (frequencies?.length) {
      const frequenciesEveryPeriod = sellingPlanGroup?.selling_plans
        ?.map(({ options }) => options || [])
        .flat()
        .map(({ value }) => textToFreq(value));
      const frequenciesText = sellingPlanGroup.options?.[0]?.values || frequencies;
      return {
        ...state,
        defaultFrequency: frequencies[0],
        frequenciesEveryPeriod,
        frequencies,
        frequenciesText
      };
    }
  }

  return state;
};

export const inStock = (state = {}, action) => {
  if (constants.RECEIVE_PRODUCT_PLANS === action.type) {
    return Object.entries(action.payload).reduce(productTrue, state);
  }

  if (constants.SETUP_CART === action.type) {
    const cart = action.payload;

    return cart.items.reduce(reduceProductCartLine, state);
  }

  if (constants.SETUP_PRODUCT === action.type) {
    const product = action.payload;

    return [product, ...product?.variants]?.reduce(productOrVariantInStockReducer, state) || state;
  }

  return state;
};

export const offer = (state = {}, action) => state;

export const offerId = (state = '', action) => 'native-shopify-offer';

export const optedin = (state = [], action) => {
  if (constants.SETUP_CART === action.type) {
    const cart = action.payload;
    return state
      .filter(it => !it.id.includes(':'))
      .concat(
        cart.items.reduce(
          (acc, cur) =>
            cur.selling_plan_allocation
              ? [...acc, { id: cur.key, frequency: `${cur.selling_plan_allocation.selling_plan.id}` }]
              : acc,
          []
        )
      );
  }
  if (constants.RECEIVE_OFFER === action.type) {
    const { autoship, autoship_by_default, in_stock, offer: offerEl } = action.payload;

    return Object.keys(autoship).reduce(
      (acc, id) =>
        acc.concat(
          !acc.some(it => it.id === id) && autoship[id] && autoship_by_default[id] && in_stock[id]
            ? { id, frequency: offerEl.defaultFrequency }
            : []
        ),
      state
    );
  }

  if (constants.SETUP_PRODUCT === action.type) {
    const productIds = action.payload.variants.map(variant => variant.id).map(id => `${id}`);
    const sellingPlanGroup = getOGSellingPlanGroup(action.payload);
    const frequencies = sellingPlanGroup?.selling_plans?.map(({ id }) => `${id}`);
    // if the product is in the list of variants and its frequency isn't a valid selling plan, replace with the first valid selling plan
    return state.map(cur => ({
      ...cur,
      frequency:
        productIds.some(id => id === cur.id) && !frequencies.some(freq => freq === cur.frequency)
          ? frequencies[0]
          : cur.frequency
    }));
  }
  return coreOptedin(state, action);
};

export const productOffer = (state = {}, action) => state;

export const productPlans = (state = {}, action) => {
  if (constants.SETUP_PRODUCT === action.type) {
    const product = action.payload;
    return (
      [product, ...product?.variants]?.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.id]: cur.selling_plan_allocations?.reduce(sellingPlanAllocationsReducer, {})
        }),
        state
      ) || state
    );
  }
  if (constants.SETUP_CART === action.type) {
    const cart = action.payload;
    return (
      cart.items.reduce(
        (acc, cur) =>
          cur.selling_plan_allocation
            ? {
                ...acc,
                [cur.key]: sellingPlanAllocationsReducer({}, cur.selling_plan_allocation)
              }
            : acc,
        state
      ) || state
    );
  }
  if (constants.RECEIVE_PRODUCT_PLANS === action.type) {
    return { ...action.payload };
  }
  return state;
};

export default combineReducers({
  auth,
  authUrl,
  autoshipByDefault,
  autoshipEligible,
  config,
  defaultFrequencies,
  eligibilityGroups,
  environment,
  firstOrderPlaceDate,
  incentives,
  inStock,
  locale,
  merchantId,
  nextUpcomingOrder,
  offer,
  offerId,
  optedin,
  optedout,
  previewStandardOffer,
  previewUpsellOffer,
  productOffer,
  productPlans,
  productToSubscribe,
  sessionId,
  templates
});
